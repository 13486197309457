import { Close as CloseIcon, Launch as LaunchIcon, WarningRounded as WarningRoundedIcon } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ObserveWarningSignal = () => {
  const lastMessageTime = useRef(null);
  const { auth } = useSelector(state => ({ auth: state.auth }), shallowEqual);
  const { user } = auth;

  const [settings, setSettings] = useState({
    open: false,
    messages: []
  });

  const hasMoreThan10SecondsPassed = providedDate => {
    if (!providedDate) {
      return true;
    }
    const date = new Date(providedDate);
    const now = new Date();
    const differenceInMilliseconds = now - date;

    return differenceInMilliseconds > 10000;
  };

  useEffect(() => {
    const socket = new WebSocket(`wss://ws.api.spalla.io/ws/${user.contaId}`);

    const handleSocketMessage = ({ data }) => {
      const jsonLines = data.split('\n');
      const fpsMessages = jsonLines.map(currentLine => JSON.parse(currentLine)).filter(currentLine => currentLine.evento === 'alerta_fps_alto');

      if (fpsMessages.length && hasMoreThan10SecondsPassed(lastMessageTime.current)) {
        lastMessageTime.current = fpsMessages[fpsMessages.length - 1].timestamp;
        setSettings({ open: true, messages: fpsMessages });
      }
    };

    socket.addEventListener('message', handleSocketMessage);

    return () => {
      socket.removeEventListener('message', handleSocketMessage);
      socket.close();
    };
  }, [user.contaId, user.id]);

  const memorizedMessageList = useMemo(
    () =>
      settings.messages.map(currentMessage => (
        <ListItem
          key={currentMessage.live_id}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <ListItemIcon
            sx={{
              display: 'list-item',
              listStyleType: 'disc',
              ml: 2,
              mr: 1,
              minWidth: 'auto'
            }}
          />
          <ListItemText primary={currentMessage.titulo} primaryTypographyProps={{ sx: { color: 'neutral.body', fontSize: 14 } }} />
          <Link target={'_blank'} to={`/streaming/${currentMessage.live_id}/panel`}>
            <IconButton edge="end">
              <LaunchIcon />
            </IconButton>
          </Link>
        </ListItem>
      )),
    [settings.messages]
  );

  return (
    <Modal open={settings.open} onClose={() => setSettings(() => ({ open: false, messages: [] }))}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          left: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500
        }}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ px: 4, py: 3 }}>
          <Stack alignItems="center" direction="row" gap={2}>
            <WarningRoundedIcon color="error" sx={{ fontSize: 24 }} />
            <Typography sx={{ color: 'neutral.main', fontSize: 16 }}>Atenção</Typography>
          </Stack>
          <IconButton onClick={() => setSettings({ open: false, messages: [] })}>
            <CloseIcon sx={{ color: 'muted', cursor: 'pointer' }} />
          </IconButton>
        </Stack>
        <Box sx={{ px: 4, py: 3, width: '100%' }}>
          <Typography sx={{ color: 'neutral.body', fontSize: 14 }}>
            Identificamos que o sinal de uma ou mais transmissão ultrapassou o limite de{' '}
            <Typography component="span" sx={{ color: 'neutral.body', fontSize: 14, fontWeight: 600 }}>
              30 FPS,
            </Typography>
            que é o determinado para a sua conta, favor adequar ao padrão estabelecido.
          </Typography>
          <List sx={{ maxHeight: 120, overflowY: 'auto' }}>{memorizedMessageList}</List>
        </Box>
        <Divider sx={{ bgcolor: 'grey.200' }} />
        <Stack alignItems="center" direction="row" justifyContent="flex-end" gap={2} sx={{ pb: 4, pt: 3, px: 4 }}>
          <Button size="medium" color="error" onClick={() => setSettings({ open: false, messages: [] })}>
            Fechar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ObserveWarningSignal;
